import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import style from "../../styles/Home.module.css";
import { actions } from "../../redux/Frontend/Help/redux/slice";
import { selectStillMakingRetreatsPlanForm } from "../../redux/Frontend/Help/redux/selector";
import CustomToast from "./CustomToast";
function StillSearching() {
  const form = useSelector(selectStillMakingRetreatsPlanForm);
  const dispatch = useDispatch();
  const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(
      actions.updateStillMakingRetreatsPlanFormValue({ key: name, value })
    );
  };

  const handleClick = () => {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
      CustomToast("Enter Valid Email ID", "ERROR");
      return;
    }
    dispatch(
      actions.stillMakingRetreatsPlan({
        callback: () => {
          dispatch(actions.clearStillMakingPlanForm());
        },
      })
    );
  };
  return (
    <Container>
      <div
        className={`${style.StillSearchingBg} rounded bg-dark-blue mb-5 mt-3`}
      >
        <Row
          className={`${style.StillSearching}  px-4 px-xl-5 px-lg-5 px-md-5`}
        >
          <Col lg={6} xs={12} className="d-flex text-white ">
            <div>
              <img src="/images/mail.svg" className="img-fluid" alt="" />
            </div>
            <div className="ms-4">
              <h4 className="textUpperCase">
                Still making your retreat plans?
              </h4>
              <h6>
                Get goodness and inspiration delivered to your inbox via our
                weekly newsletter.
              </h6>
            </div>
          </Col>
          <Col lg={6} className={`${style.subscribe}`}>
            <div className="input-group d-flex d-lg-flex flex-lg-row col-12 ">
              <div className="col-12 col-sm-12 col-md-9 col-xl-10 ">
                <div className="d-flex flex-row align-items-center justify-content-start bg-white subscribe-email-input">
                  <img
                    src="/images/email-icon.svg"
                    className="col-lg col-md col-sm-3 ps-3  ps-md-1 ps-lg-1 img-lg-fluid email-img"
                  />
                  <input
                    type="email"
                    className=" border-0 subscribe-email-text col-sm-9 col-md-9 col-lg-11 ps-2 py-3 py-sm-3 py-md-3 py-lg-3"
                    placeholder="Enter your email address"
                    aria-label="Recipient's email"
                    aria-describedby="button-addon2"
                    required
                    value={form?.email}
                    name="email"
                    onChange={handleFieldChange}
                  />
                </div>
                <span className="text-white d-none d-md-block">
                  Note: We respect your privacy. We will not publish or share
                  your email address in any way.
                </span>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-xl-2 mt-3 mt-md-0">
                <button
                  className="subscribe-button subscribe-email-text  bg-dark border-0 text-white fs-12 p-3 p-sm-3 p-md-3 p-lg-3 w-100 mt-lg-0 "
                  type="button"
                  id="button-addon2"
                  onClick={handleClick}
                >
                  Subscribe
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default StillSearching;
