import Link from "next/link";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import React, { useEffect } from 'react';
import styles from "../styles/Home.module.css";
import CustomToast from "./UI/CustomToast";
import { useSelector, useDispatch } from "react-redux";

import { useRouter } from "next/router";
import Currency from "../hooks/currencyshow";
import { path } from "../utils/Api";
import { actions } from "../redux/Frontend/WishList/redux/slice";
import { actions as actionsSignIn } from "../redux/Login/redux/slice";
import { selectUserRole } from "../redux/Login/redux/selector";
import { selectAllActiveCurrencyListData } from "../redux/School/SchoolPayments/redux/selector";

export default function ProductCard(props: any) {
  const {
    overview,
    price,
    reviewScore,
    noOfReviews,
    photo,
    type,
    coupon,
    gift,
    retreatId,
    image,
    wishlistId,
    images,
    popular,
    seoTitle,
    seoActivityTitle,
  } = props;
  const [toggleWishlist, setToggleWhislist] = useState(false);
  // const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishlistId) {
      setToggleWhislist(true);
    }
  }, [wishlistId]);
  const handleToggle = () => {
    setToggleWhislist(!toggleWishlist);
  };

  const handleToggleRemove = () => setToggleWhislist(false);
  const handleShowSignin = () => {
    dispatch(actionsSignIn.SignInFormShowHide(true));
  };
  const userType = useSelector(selectUserRole);

  const handleWishlist = async (retreatId: any) => {
    if (userType == 3) {
      handleToggle();
      if (!toggleWishlist) {
        dispatch(
          actions.updateWishListFormValue({
            key: "id",
            value: retreatId,
          })
        );
        dispatch(
          actions.doAddWishlist({
            callback: () => {
              dispatch(actions.doGetWishlist());
              dispatch(actions.toggalWishListIcon());
            },
          })
        );
      } else {
        dispatch(
          actions.doRemoveWishList({
            id: retreatId,
            callback: () => {
              dispatch(actions.doGetWishlist());
              dispatch(actions.toggalWishListIcon());
            },
          })
        );
      }
    } else {
      handleShowSignin();
    }
  };
  const Currencies = useSelector(selectAllActiveCurrencyListData);
  return (
    <>
      <div
        className="col-12 col-sm-6 col-lg-3  my-2 my-sm-2 my-md-2 my-lg-0 pe-md-2"
      // style={{ paddingRight: "10px" }}
      >
        <div className="fa-heart-wish-container pointer">
          <FontAwesomeIcon
            icon={["fas", "heart"]}
            width="19"
            onClick={() => handleWishlist(retreatId)}
            style={{
              color:
                toggleWishlist || wishlistId == 1 ? "#3165BA" : "#00000082",
              cursor: "pointer",
            }}
            className="fa-heart-wish pointer"
          />
        </div>
        <Link href={`/r/${seoActivityTitle}/${seoTitle}`} target="_blank">
          <div className={`col-lg-12 ${styles.productCard} mb-3 pointer h-100`}>
            <div
              className={`${styles.image_container} d-none d-lg-block `}
              style={{
                backgroundImage: ` url(${path()}${image})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                minHeight: "250px",
                maxHeight: "250px",
                position: "relative",
                backgroundPosition: "center center",
              }}
            >
              {popular == 1 ? (
                <div className="badge-popular">POPULAR</div>
              ) : (
                gift == 1 && <div className="badge-free-gift ">FREE GIFT</div>
              )}
              {coupon == 1 && (
                <div
                  className={`badge bg-primary p-2 m-1 ${styles.coupon_badge}`}
                >
                  <p className="fs-10 my-0">Coupon Available</p>
                </div>
              )}
            </div>
            {/* Mobile */}
            <div
              className={`${styles.image_container} d-md-none d-block mb-3 img-fluid `}
              style={{
                backgroundImage: ` url(${path()}${image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                minHeight: "320px",
                maxHeight: "400px",
                position: "relative",
              }}
            >
              {popular == 1 ? (
                <div className="badge-popular">POPULAR</div>
              ) : (
                gift == 1 && (
                  <div className="badge-free-gift fs-12">FREE GIFT</div>
                )
              )}
              {coupon == 1 && (
                <div
                  className={`badge bg-primary p-2 m-1 ${styles.coupon_badge}`}
                >
                  <p className="fs-11 my-0">Coupon Available</p>
                </div>
              )}
            </div>

            {/* Tablet */}
            <div
              className={`${styles.image_container} d-none d-lg-none d-md-block `}
              style={{
                backgroundImage: ` url(${path()}${image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                minHeight: "250px",
                maxHeight: "250px",
                position: "relative",
              }}
            >
              {popular == 1 ? (
                <div className="badge-popular">POPULAR</div>
              ) : (
                gift == 1 && <div className="badge-free-gift ">FREE GIFT</div>
              )}
              {coupon == 1 && (
                <div
                  className={`badge bg-primary p-2 m-1 ${styles.coupon_badge}`}
                >
                  <p className="fs-10 my-0">Coupon Available</p>
                </div>
              )}
              <div className="d-flex justify-content-end"></div>
            </div>
            <div className="px-3 pt-2 black333" style={{ minHeight: "4.9rem" }}>
              <p className="product-card-header  textUpperCase">
                {type ? type : "AYURVEDIC RETRETAS"}
              </p>
              <h5 className="fs-12 mt-2 mb-0 ">
                <p
                  className="retreat-fs-13 text-decoration-none  fw-500 black333 textUpperCase text-wrap2"
                  style={{ lineHeight: "1.44!important" }}
                >
                  {overview
                    ? overview?.length > 80
                      ? `${overview?.slice(0, 50)}${"..."}`
                      : overview
                    : ""}
                </p>
              </h5>
            </div>

            <div className="col-12 d-flex flex-row justify-content-between px-3 my-2">
              <div className="col-lg-6 d-flex flex-column justify-content-end">
                <span
                  className={`${styles.rating} p-0 d-flex align-items-center mb-1`}
                >
                  <FontAwesomeIcon
                    icon={["fas", "star"]}
                    width="14"
                    className="m-0 retreat-text"
                  />{" "}
                  <span className="p-0 fs-10 fw-500 m-0 ms-2">
                    {Number(reviewScore.toFixed(1))}/5{" "}
                    {noOfReviews > 0 && <> ({noOfReviews})</>}
                  </span>
                </span>
              </div>
              <div
                className={`col-lg-6 d-flex align-items-end justify-content-end flex-column`}
              >
                <small className="fs-10 paragraph">From</small>
                <Currency
                  value={Number(price)?.toFixed(0)}
                  type={Currencies}
                  className={"fs-6 fw-semibold text-dark"}
                />
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}
