import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import style from "../../styles/Home.module.css";
import Image from "next/image";

function DiscoverRetreats() {
  return (
    <div>
      <Row className="mt-3 mt-sm-3 mt-md-5 mt-lg-5">
        {discoverRetreats.map((item, i) => (
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            key={i}
            className={`d-none d-md-block`}
          >
            <Row className={`${style.discoverRetreats}`}>
              <Col xs={4} className="pe-0 text-center">
                <Image
                  src={item.img}
                  alt="Picture of the author"
                  width={88}
                  height={88}
                  className="img-fluid byr-discover-retreat-image"
                />
                {/* <img src={item.img} className="img-fluid" width={"90vh"} /> */}
              </Col>
              <Col xs={8}>
                <h4 className=" mb-1">{item.heading}</h4>
                <h6 className="m-0 text-title-black ">{item.text}</h6>
              </Col>
            </Row>
          </Col>
        ))}
        {discoverRetreats.slice(0, 4).map((item, i) => (
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            key={i}
            className="mt-2 d-block d-md-none"
          >
            <Row className={`${style.discoverRetreats}`}>
              <Col xs={3} sm={4} className="pe-0 text-center">
                <Image
                  src={item.img}
                  alt="Picture of the author"
                  width={88}
                  height={88}
                  className="img-fluid byr-discover-retreat-image"
                />
                {/* <img src={item.img} className="img-fluid " /> */}
              </Col>
              <Col xs={9} sm={8}>
                <h4 className=" ">{item.heading}</h4>
                <h6 className="m-0 text-title-black">{item.text}</h6>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
      <div className="row mt-3 mt-sm-3 mt-md-5 mt-lg-5"></div>
    </div>
  );
}

export default DiscoverRetreats;

const discoverRetreats = [
  {
    img: "/images/luxury-retreat.jpg",
    heading: "Luxury Retreats",
    text: "Soak in the most magnificent views with yoga therapies.",
  },
  {
    img: "/images/ayurvedic-retreat.jpg",
    heading: "Ayurvedic Retreats",
    text: "Experience the ancient healing therapies & rejuvenate.",
  },
  {
    img: "/images/yoga-tour.jpg",
    heading: "Yoga Tours & Cruises",
    text: "Quality yoga retreats at unseen destinations & surreal views.",
  },
  {
    img: "/images/yoga-holidays.jpg",
    heading: "Yoga Holidays",
    text: "Enjoy the vacation season with relaxing yoga retreats.",
  },
  {
    img: "/images/affordable-yoga.jpg",
    heading: "Affordable Yoga Retreats",
    text: "It’s never too late to pick your favorite & cost-effective retreat.",
  },
  {
    img: "/images/health-retreat.jpg",
    heading: "Health Retreats",
    text: "Both physical and mental healing starts within you; heal with the most recommended retreats.",
  },
  {
    img: "/images/yoga-surf.jpg",
    heading: "Yoga & Surf",
    text: "Who said yoga isn’t fun & exciting? Revel in the adventurous side of yoga retreats.",
  },
  {
    img: "/images/yoga-tour1.jpg",
    heading: "Yoga for Mind",
    text: "Don’t escape stress; go for the dedicated mind-yoga retreats & come back stronger.",
  },
];
