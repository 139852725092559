import { useRouter } from "next/router";
import Image from "next/image";
import React from "react";
import { useDispatch } from "react-redux";
import { actions } from "../redux/Frontend/ListingsFilter/redux/slice";
import { RetreatsTypeEnum } from "../redux/Frontend/ListingsFilter/types";
function UniqueExperiencesSec() {
  const router = useRouter();
  const dispatch = useDispatch();
  const _handleroute = (retreatTypeFilter: any, activityFilter: any) => {
    let qr = { retreatTypeFilter, activityFilter };
    !qr.retreatTypeFilter && delete qr.retreatTypeFilter;
    !qr.activityFilter && delete qr.activityFilter;
    dispatch(
      actions.updateListingsFilterFormValue({
        key: "retreatTypeFilter",
        value: parseInt(qr.retreatTypeFilter) || [],
      })
    );
    if (qr.activityFilter?.length > 0) {
      dispatch(actions.setActivityList(qr.activityFilter));
    }
    router.push({
      pathname: "/listing",
      query: qr,
    });
  };
  return (
    <>
      <div className="row mt-3 mt-md-5" id="byr-safe-travel-row">
        {UniqueExperiences.map((item, i) => (
          <div
            key={i}
            className={`col-6 col-sm-6 col-lg-3 pt-3 pt-sm-4 pt-lg-0 pointer`}
            onClick={() =>
              _handleroute(item.retreatTypeFilter, item.activityFilter)
            }
            style={{
              position: "relative",
            }}
          >
            <div
              className="imageContainer byr-image-rounder-homepage unique-experience-image rounded"
              style={{
                overflow: "hidden",
                height: "100%",
              }}
            >
              <Image
                src={item.img}
                alt="Picture of the author"
                width={276}
                height={276}
                style={{ minHeight: "100%" }}
                className="img-fluid byr-category-image-list"
              />
              {/* <img src={item.img} alt="" className="img-fluid" /> */}
            </div>
            <div
              className="byr-homepage-overlap-text-wrap"
              style={{ position: "absolute", bottom: "0" }}
            >
              <p className="text-white   fw-500 ms-sm-2 ms-md-3 pointer unique-experience">
                {item.title}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default UniqueExperiencesSec;

const UniqueExperiences = [
  {
    img: "/images/productImage2.jpg",
    title: "Yoga Retreat",
    retreatTypeFilter: `${RetreatsTypeEnum.Offline}`,
    activityFilter: "Yoga Retreat",
  },
  {
    img: "/images/productimage.jpg",
    title: "Yoga Teacher Training",
    retreatTypeFilter: `${RetreatsTypeEnum.Offline}`,
    activityFilter: "Yoga Teacher Training",
  },
  {
    img: "/images/productImage1.jpg",
    title: "Online Yoga Retreat",
    retreatTypeFilter: `${RetreatsTypeEnum.Online}`,
    activityFilter: "Yoga Retreat",
  },
  {
    img: "/images/productImage4.jpg",
    title: "Online Yoga Training",
    retreatTypeFilter: `${RetreatsTypeEnum.Online}`,
    activityFilter: "Yoga Teacher Training",
  },
];
